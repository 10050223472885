<template>
  <div id="painting">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />海报
        </van-col>
         <van-col
          span="14"
          style="text-align:right"
          class="header-title"
          @click="onPosterChange"
        >
          换一个
        </van-col>
      </van-row> 
    </div>
    <div class="painting-container">
      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide >
          <img :src="img" />
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
      
    </div>
    <!-- <vue-canvas-poster
        v-for="(item, index) in paintings"
        :widthPixels="1000"
        :key="index"
        :painting="item"
        @success="
          (param) => {
            return success(param, index);
          }
        "
        @fail="(param) => {
            return fail(param, index);
          }"
      ></vue-canvas-poster> -->
      <vue-canvas-poster
        :widthPixels="1000"
        :painting="paintings[posterIndex-1]"
        @success="
          (param) => {
            return success(param);
          }
        "
        @fail="(param) => {
            return fail(param);
          }"
      ></vue-canvas-poster>
       <!-- <vue-canvas-poster
        :widthPixels="1000"
        :painting="painting1"
        @success="
          (param) => {
            return success(param);
          }
        "
        @fail="(param) => {
            return fail(param);
          }"
      ></vue-canvas-poster> -->
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Toast } from "vant";
export default {
  props: {},
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    const _self = this;
    return {
      img: "",
      painting: {},
      painting1: {},
      painting2: {},
      paintings: [],
      modelShow:false,
      posterIndex:1,
      imgs: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        freeMode: true,
        // slidesPerView: 1,
        // spaceBetween: 30,
        // coverflowEffect: {
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      plans: {},
    };
  },
  created() {
    const id = this.$route.query.id;
    this.get_travel_plan(id);
  },
  mounted() {},
  methods: {
    success(src) {
        this.img = src
    },
    fail(err) {
      console.log("fail", err);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    start() {
      var that = this;
      this.longClick = 0;
      this.timeOutEvent = setTimeout(function () {
        that.longClick = 1;
        that.downImg();
      }, 500);
    },
    downImg() {
      if (!window.plus) return;
      plus.gallery.save(
        this.img,
        function () {
          Toast("保存成功！");
        },
        function () {
          Toast("保存失败，请重试!");
        }
      );
    },
    async get_travel_plan(id) {
      const res = await this.$apis.get_travel_plan(id);
      this.plans = res.data;
      const image_url = res.data.image_url;
      // var jingdian = "";
      // res.data.nodes.forEach((item, index) => {
      //   if (index == res.data.nodes.length - 1) {
      //     jingdian =
      //       jingdian + item.node_name + "(" + item.node_description + ")";
      //   } else {
      //     jingdian =
      //       jingdian + item.node_name + "(" + item.node_description + ")" + "-";
      //   }
      // });

      var painting = {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientWidth * 1.777}px`,
        background: "#f4f5f7",
        debug:true,
        views: [
          {
            type: "image", //海报背景
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211119/20211119113153_i66x.png",
            css: {
              height: `${document.documentElement.clientWidth * 1.777}px`,
              top: "0",
              left: "0",
              mode: "aspectFill",
            },
          },
          {
            type: "image", //
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211119/20211119113154_yn1y.png",
            css: {
              width: `90%`,
              height: `auto`,
              top: "25%",
              left: `5%`,
            },
          },
          // {
          //   type: "image", //海报头像
          //   url: image_url,
          //   css: {
          //     width: `${document.documentElement.clientWidth / 7.5}px`,
          //     height: `${document.documentElement.clientWidth / 7.5}px`,
          //     top: `20px`,
          //     left: `20px`,
          //     borderRadius: "50%",
          //   },
          // },
          {
            type: "text",
            text: res.data.plan_name,
            css: [
              {
                top: `70px`,
                left: `10%`,
                color: "#326C8F",
                width: "90%",
                fontSize: "45px",
                fontWeight: "bold",
                textAlign: "center",
              },
            ],
          },
          {
            type: "text",
            text: `TRAVEL`,
            css: [
              {
                top: `28%`,
                left: `0px`,
                // color: "#326C8F",
                color: "#fff",
                width: "100%",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
              },
            ],
          },
          {
            type: "text",
            text: `${res.data.start_addr} - ${res.data.end_addr}`,
            css: [
              {
                top: `38%`,
                left: `0px`,
                color: "#326C8F",
                width: "100%",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
              },
            ],
          },
          // {
          //   type: "text",
          //   text: `${res.data.description}`,
          //   css: [
          //     {
          //       top: `36%`,
          //       width: "78%",
          //       left: `11%`,
          //       color: "#326C8F",
          //       fontSize: "16px",
          //       textAlign: "left",
          //       lineHeight: "20px",
          //       maxLines: "3",
          //     },
          //   ],
          // },
        ],
      };
      var nodes = res.data.nodes
      
      nodes.forEach((item, index) => {
        painting.views.push({
          type: "text",
          text: `${item.node_name}(${item.node_description})`,
          css: [
            {
              top: 50 + index * 3.5 + "%",
              width: "78%",
              left: `11%`,
              color: "#326C8F",
              fontSize: "16px",
              textAlign: "left",
              lineHeight: "20px",
              fontWeight: "bold",
            },
          ],
        });
        painting.views.push({
          type: "image",
          url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211119/20211119141655_zo7j.png",
          css: [
            {
              top: 50 + index * 3.6 + "%",
              width: "15px",
              height: `auto`,
              left: `5%`,
            },
          ],
        });
      });

      var painting1 = {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientWidth * 1.777}px`,
        background: "#f4f5f7",
        views: [
          {
            type: "image", //海报背景
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211119/20211119144323_wsn4.png",
            css: {
              // width: "100%",
              height: `${document.documentElement.clientWidth * 1.777}px`,
              //  height:'100vh',
              top: "0",
              left: "0",
              mode: "aspectFill",
            },
          },
          {
            type: "text",
            text: res.data.plan_name,
            css: [
              {
                top: `40px`,
                left: `8%`,
                color: "#10E9F2",
                width: "90%",
                fontSize: "45px",
                fontWeight: "bold",
                textAlign: "left",
                lineHeight: "50px",
              },
            ],
          },
          {
            type: "text",
            text: `${res.data.start_addr} - ${res.data.end_addr}`,
            css: [
              {
                top: `22%`,
                left: `8%`,
                color: "#fff",
                width: "100%",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "left",
              },
            ],
          },
          // {
          //   type: "text",
          //   text: `${res.data.description}`,
          //   css: [
          //     {
          //       top: `26%`,
          //       width: "80%",
          //       fontFamily: "STSongti-SC-Bold",
          //       left: `8%`,
          //       color: "#fff",
          //       fontSize: "16px",
          //       textAlign: "left",
          //       lineHeight: "20px",
          //        maxLines: "4",
          //     },
          //   ],
          // },
        ],
      };
      var nodes2 = res.data.nodes
      if(nodes2.length>6){
          nodes2 = nodes2.slice(0,6)
          painting1.views.push({
          type: "text",
          text: `. . .`,
          css: [
            {
              bottom:  "8%",
              // width: "40%",
              left: `8%`,
              color: "#fff",
              fontSize: "16px",
              textAlign: "left",
              lineHeight: "20px",
              fontWeight: "bold",
              // background: "#fff",
              padding: "6px",
              borderRadius: "5px",
            },
          ],
        });
      }
      nodes2.forEach((item, index) => {
        painting1.views.push({
          type: "text",
          text: `${item.node_name}\n(${item.node_description})`,
          css: [
            {
              top: 30 + index * 10 + "%",
              // width: "40%",
              left: `8%`,
              color: "#075A88",
              fontSize: "16px",
              textAlign: "left",
              lineHeight: "20px",
              fontWeight: "bold",
              background: "#fff",
              padding: "6px",
              borderRadius: "5px",
            },
          ],
        });
       
      });
      
      var painting2 = {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientWidth * 1.777}px`,
       
        background: "#f4f5f7",
        views: [
          {
            type: "image", //海报背景
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211121/20211121113804_6eve.jpg",
            css: {
              height: `${document.documentElement.clientWidth * 1.777}px`,
              top: "0",
              left: "0",
              mode: "aspectFill",
            },
          },
          {
            type: "image", //背景圆
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211121/20211121113804_k11n.png",
            css: {
            width: "70%",
              height: `auto`,
              top: "40px",
              left: "15%",
              mode: "aspectFill",
            },
          },
          {
            type: "image", //旅行在别处
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211121/20211121200247_fgim.png",
            css: {
              width: "20%",
              height: `auto`,
              top: "50%",
              right: "10%",
              mode: "aspectFill",
            },
          },
          {
            type: "text",
            text: res.data.plan_name,
            css: [
              {
                top: `120px`,
                left: `20%`,
                color: "#fff",
                width: "60%",
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
              },
            ],
          },
          // {
          //   type: "text",
          //   text: `TRAVEL`,
          //   css: [
          //     {
          //       top: `22%`,
          //       left: `0px`,
          //       color: "#fff",
          //       width: "100%",
          //       fontSize: "30px",
          //       fontWeight: "bold",
          //       textAlign: "center",
          //     },
          //   ],
          // },
          // {
          //   type: "text",
          //   text: `旅行在别处，我想去看看`,
          //   css: [
          //     {
          //       top: `38%`,
          //       left: `30%`,
          //       color: "#fff",
          //       width: "40%",
          //       fontSize: "20px",
          //       fontWeight: "bold",
          //       textAlign: "center",
          //     },
          //   ],
          // },
          {
            type: "text",
            text: `${res.data.start_addr} - ${res.data.end_addr}`,
            css: [
              {
                top: `28%`,
                left: `0px`,
                color: "#fff",
                width: "100%",
                fontSize: "25px",
                fontWeight: "bold",
                textAlign: "center",
              },
            ],
          },
          // {
          //   type: "text",
          //   text: `${res.data.description}`,
          //   css: [
          //     {
          //       top: `50%`,
          //       width: "50%",
          //       left: `40%`,
          //       color: "#fff",
          //       fontSize: "16px",
          //       textAlign: "left",
          //       lineHeight: "20px",
          //     },
          //   ],
          // },
        ],
      };
       var nodes3 = res.data.nodes
      if(nodes3.length>6){
          nodes3 = nodes3.slice(0,6)
          painting2.views.push({
          type: "text",
          text: `. . .`,
          css: [
            {
              bottom:  "5%",
              // width: "40%",
              left: `10%`,
              color: "#fff",
              fontSize: "16px",
              textAlign: "left",
              lineHeight: "20px",
              fontWeight: "bold",
              // background: "#fff",
              padding: "6px",
              borderRadius: "5px",
            },
          ],
        });
      }
        nodes3.forEach((item, index) => {
        painting2.views.push({
          type: "text",
          text: `${item.node_name}\n(${item.node_description})`,
          css: [
            {
              top: 46 + index * 8 + "%",
              left: `10%`,
              color: "#fff",
              fontSize: "12px",
              textAlign: "left",
              lineHeight: "20px",
              fontWeight: "bold",
              background: "rgba(255,255,255,.2)",
              padding: "6px",
              textAlign: "left",
              borderRadius: "5px",
            },
          ],
        });
      });

      // this.$nextTick(() => {
        // this.painting = painting
        // this.painting1 = painting1
        // this.painting2 = painting2
        this.paintings = [painting1, painting, painting2];
        // this.modelShow = true
      // });
    },
    onPosterChange(){
      if(this.posterIndex==1){
        this.posterIndex = 2
      }else if(this.posterIndex==2){
        this.posterIndex = 3
      }else if(this.posterIndex==3){
        this.posterIndex = 1
      }
    }
  },
};
</script>
<style  scoped>
#painting {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top: 10vh;
  /* overflow-y: scroll; */
  /* padding-bottom: 6rem; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.painting-container {
  height: 100vh;
  margin-top: 2vh;
  overflow-y: scroll;
  padding-bottom: 2rem;
  width: 95%;
  margin: 0 auto;
  text-align: center;
}
.swiper-pagination {
  /* background-color: white; */
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  /* background-color: white; */
}
</style>